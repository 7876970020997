import axios from 'axios'

const state = {
  machines: [],
  selectedMachine: null,
}

const mutations = {
  SET_MACHINES: (state, machines) => state.machines = machines,
  SELECT_MACHINE: (state, machine) => state.selectedMachine = machine,
  UPDATE_MACHINE: (state, { field, value }) => {
    state.selectedMachine[field] = value
  },
}

const actions = {
  fetchMachines({ commit, rootState }) {
    const query = { merchantId: rootState.merchants.selected.id };
    axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/machines/admin`, { params: query })
      .then((r) => commit('SET_MACHINES', r.data))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}