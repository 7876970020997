const state = {
  show: false,
}

const mutations = {
  SHOW(state) {
    state.show = true
  },
  HIDE(state) {
    state.show = false
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}