import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: '#607D8B',
        secondary: '#455A64',
        accent: '#FDD835',
      },
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
});