import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const LoginApp = () => import(/* webpackChunkName: "login" */ '@/components/LoginApp.vue')

const AdminApp = () => import(/* webpackChunkName: "admin" */ '@/components/AdminApp.vue')
const Merchants = () => import(/* webpackChunkName: "admin" */ '@/components/Merchants/Merchants.vue')
const MerchantCreate = () => import(/* webpackChunkName: "admin" */ '@/components/Merchants/MerchantCreate.vue')
const Machines = () => import(/* webpackChunkName: "admin" */ '@/components/Machines/Machines.vue')

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: { name: 'merchants' },
    },
    { name: 'login', path: '/login', component: LoginApp },
    {
      path: '/app',
      component: AdminApp,
      meta: { requiresAuth: true, roles: ['Admin'] },
      children: [
        { name: 'merchants', path: 'merchants', component: Merchants, },
        { name: 'merchantCreate', path: 'merchants/create', component: MerchantCreate, },
        { name: 'machines', path: 'machines', component: Machines, },
        { path: '', redirect: { name: 'merchants' } }
      ],
    },
  ]
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    const token = localStorage.getItem('token');
    if (token) {
      const role = parseJwt(token).role;
      const hasAccess = to.matched.some(record => record.meta.roles && record.meta.roles.some(r => r === role));
      if (hasAccess) {
        next();
      } else {
        next({ name: 'login' });
      }
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }
})

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export default router