import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './components/Snackbar/snackbar.js'
import progress from './components/Progress/progress.js'
import merchants from './components/Merchants/merchants.js'
import machines from './components/Machines/machines.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    snackbar,
    progress,
    merchants,
    machines,
  },
})