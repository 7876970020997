import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', function (value) {
  if (!value) return ''

  return moment(value).format('DD-MM-YYYY')
})

Vue.filter('date_ordered', function (value) {
  if (!value) return ''

  return moment(value).format('YYYY-MM-DD')
})

Vue.filter('fulldate', function (value) {
  if (!value) return ''

  return moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('duration', function (value) {
  if (!value) return ''

  const duration = moment.duration(value, 'minutes');

  const days = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, 'days'));

  const hours = duration.hours();
  duration.subtract(moment.duration(hours, 'hours'));

  const minutes = duration.minutes();
  duration.subtract(moment.duration(minutes, 'minutes'));

  let result = '';
  if (days > 0) {
    result += `${days} д  `;
  }
  if (hours > 0) {
    result += `${hours} час  `;
  }
  if (minutes > 0) {
    result += `${minutes} мин `;
  }
  return result || 'менее 1 мин';
})

Vue.filter('money', function (value) {
  if (!value) return value

  return formatMoney(value, 2, '.', ' ');
})

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}