const state = {
  show: false,
  message: '',
}

const mutations = {
  SHOW(state, payload) {
    state.message = payload || "Произошла ошибка"
    state.show = true
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}