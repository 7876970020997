import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store.js'
import router from './router.js'

/* eslint-disable no-unused-vars */
import index from './filters.js'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios.interceptors.request.use(
      request => {
        this.$store.commit('progress/SHOW');
        return request;
      },
      error => {
        this.$store.commit('progress/HIDE');
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      response => {
        this.$store.commit('progress/HIDE');
        return response;
      },
      error => {
        this.$store.commit('progress/HIDE');
        if (error.message === "Network Error") {
          this.$store.commit('snackbar/SHOW', "Проверьте интернет-соединение");
          return Promise.reject(error);
        }

        if (error.response.status === 400) {
          var message = error.response.data.Message || error.response.data.message;
          this.$store.commit('snackbar/SHOW', message);
        }
        if (error.response.status === 401) {
          localStorage.removeItem('token')
          location.reload()
        }
        return Promise.reject(error);
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
